/**
 * Custom jQuery "key-map" functions
 * ( ...feel free to add your own :-D )
 */
$( document ).ready(function() {
	
	document.onkeyup = function(key) {
		
		//alert(key.which);
		
		const basePath = window.location.origin;

		/**
		 * key : Alt + N
		 *
		 * Navigates to the dashboard
		 */
		if (key.altKey && key.which === 78) {

			window.location.href = basePath + "/dashboard/overview";
		}
		
	};
	
});
